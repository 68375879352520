<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id="printMe" style=" width: 21cm; margin: 0 auto;">
      <div class="innerPage">
        <div class="title flexCenter">{{ info.school_year }}学年度第___学期毕业班班主任工作台帐</div>
        <div style="display: flex;justify-content: space-around;margin-top: 20px;">
          <span>{{ info.title }}</span>
          <span>部门&nbsp;&nbsp;&nbsp;&nbsp;{{ info.dept_name }}</span>
          <span>班主任姓名&nbsp;&nbsp;&nbsp;&nbsp;{{ info.username }}</span>
        </div>
        <table class="table" style="width: 100%;" border="1">
          <tr>
            <th style="min-width: 150px;">时间</th>
            <th>工作内容</th>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">推荐与落实（含落实学生姓名及企业名称</td>
          </tr>
          <tr v-for="(item,index) in detail.tuijian" :key="index + 'tj'">
            <td>{{ item.sTime }} - {{ item.eTime }}</td>
            <td>{{ item.content || '-' }}</td>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">学生指导与管理（含就业指导）</td>
          </tr>
          <tr v-for="(item,index) in detail.zhidao" :key="index + 'zd'">
            <td>{{ item.sTime }} - {{ item.eTime }}</td>
            <td>{{ item.content || '-' }}</td>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">走访与联络（含走访企业名称、接待人姓名、职务、联系电话及学生情况、企业反馈）</td>
          </tr>
          <tr v-for="(item,index) in detail.zoufang" :key="index + 'zf'">
            <td>{{ item.sTime }} - {{ item.eTime }}</td>
            <td>{{ item.content || '-' }}</td>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">其它（含偶发事件与处理）</td>
          </tr>
          <tr v-for="(item,index) in detail.other" :key="index + 'o'">
            <td>{{ item.sTime }} - {{ item.eTime }}</td>
            <td>{{ item.content || '-' }}</td>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">建议与设想（含学校课程设置、专业建设等方面）</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ detail.ideas || '-' }}</td>
          </tr>
          <tr>
            <td></td>
            <td class="td-title">各系分管领导审核意见</td>
          </tr>
          <tr>
            <td></td>
            <td>{{ info.remark || '-' }}</td>
          </tr>
        </table>
        <p>注：各毕业班班主任每两周上报一次，每学期双周五前将上两周工作情况台帐送各系分管领导审核，并于次周一
          各系统一以电子文本形式报招生就业处，台帐作为各系就业工作及毕业班班主任工作绩效考核的依据之一。</p>
      </div>
    </div>


    <!--    <el-button v-print="'#printMe'" type="primary" style="position: fixed;top: 60px;right: 10px;z-index: 999;">-->
    <!--      打印-->
    <!--    </el-button>-->

  </div>
</template>

<script>
export default {
  name: 'export',
  components: {},
  data() {
    return {
      pdfok: false,
      show_dev: false,
      show_dev1: true,
      dataover222: true,
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language" content="zh-cn"/>'
      },
      userid: '',

      stuInfo: {},
      yuebao: [{}],
      zhoubao: [{}],
      chinaCitys: [],
      // 填入导出的pdf文件名和html元素
      pdfTitle: '试卷1',
      pdfSelector: '#printMe',
      info: {},
      detail: {}
    }
  },
  mounted() {
    let id = this.getParams('id')
    let pmid = this.getParams('pmid')
    // let id = 'fd05786013204a96ae7586c9cd77a32a'
    console.log(1111111, id, pmid)
    this.getChinaCity().then(rr => {
    })
    this.getDataList(id, pmid)

  },
  methods: {
    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var pattern = new RegExp('[&mdash;]')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '-')
      }
      return rs
    },

    getChinaCity() {
      return new Promise(resolve => {
        this.$http.post("/api/china_citys").then(res => {
          this.chinaCitys = res.data
          resolve()
        })
      })
    },
    findDeep(list, iid) {
      for (let i in list) {
        if (list[i].value === iid) {
          return [list[i]]
        }
        if (list[i].children != null) {
          let node = this.findDeep(list[i].children, iid);
          if (node !== undefined) {
            return node.concat(list[i])
          }
        }
      }

    },
    getDataList(id, pmid, status) {
      let _this = this
      this.$http.post('/api/export_wxjd_tch_ledger', {
        id: parseInt(id) ? parseInt(id) : id,
        pmid: parseInt(pmid)
      }).then(res => {
        if (res.data) {
          let rData = res.data
          this.detail = JSON.parse(rData.detail)
          this.info = rData
        }

        this.$nextTick(() => {
          setTimeout(() => {
            _this.pdfok = true
          }, 500)
        })

      })

    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
<style scoped>
.table {
  border-collapse: collapse;
  text-align: center;
  line-height: 25px;
}

.table, .table tr td, tr th {
  border: 1px solid #000000;
  padding: 4px;
}

.table tr {
  page-break-inside: avoid;
}
.title {
  font-size: 20px;
  font-weight: bold;
}

.td-title {
  font-weight: bold;
  font-size: 15px;
}
</style>
